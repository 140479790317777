import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AuthService } from 'src/app/services/auth.service';
import { FeedbackService } from 'src/app/services/feedback.service';

@Component({
  selector: 'app-pinlock',
  templateUrl: './pinlock.page.html',
  styleUrls: ['./pinlock.page.scss'],
})
export class PinlockPage implements OnInit {
  public pinNumber:any='';

  constructor(
    private _feedback:FeedbackService,
    private _auth:AuthService,
    private _modalCtrl:ModalController
  ) { }

  ngOnInit() {
  }

  public toLogin()
  {
    this._modalCtrl.dismiss();
    this._auth.logout();
  }

  public submitPin()
  {
    if(!this.pinNumber || this.pinNumber.length != 4)
    {
      console.log(this.pinNumber);
      this._feedback.setShowMessage(false, 'Enter your 4 digit PIN');
      return;
    }
    this._feedback.setShowWorking();
    this._auth.validatePin(this.pinNumber).subscribe(
      res => {
        if(!res)
        {
          this._feedback.setShowMessage(false, 'Invalid PIN');
          this.pinNumber='';
        }
        else
        {
          this._feedback.setShowMessage(true, 'Successfully unlocked');
          this._auth.resetTimer();
          this._modalCtrl.dismiss();
        }
        this._feedback.unsetShowWorking();
      }
    );
  }
}

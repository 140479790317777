import { Department } from "./department";
import { Organisation } from "./organisation";
import { Role } from "./role";

export class User {
  first_name: string;
  last_name: string;
  email: string;
  fake_email: boolean=false;
  telephone: string;
  id: number;
  roles:Role[]=[];
  departments:Department[]=[];
  organisations:Organisation[]=[];
  force_password_change:boolean=true;
  enabled:boolean=true;
  deleted_at:Date;

  constructor(user?)
  {
    if(user)
    {
      this.first_name=user.first_name;
      this.last_name=user.last_name;
      this.email=user.email;
      this.fake_email=user.fake_email;
      this.telephone=user.telephone;
      this.id=user.id;
      if(user.roles)
      {
        this.roles=[];
        user.roles.forEach(r => {
          this.roles.push(new Role(r));
        });
      }
      this.departments=user.departments;
      this.organisations=user.organisations;
      this.force_password_change=user.force_password_change;
      this.enabled=user.enabled;
      this.deleted_at=user.deleted_at;
    }
  }
}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { FeedbackService } from '../services/feedback.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {

  constructor(
    private _auth: AuthService,
    private _feedback: FeedbackService
  ){}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
  {
    if(this._auth.testUserRole('Super Admin')) return true;
    let response = true;
    if(route.data.not)
    {
      route.data.not.forEach(r => {
        if(this._auth.testUserRole(r)) response=false;
      });
    }

    if(route.data.roles)
    {
      let response = false;
      route.data.roles.forEach(r => {
        if(this._auth.testUserRole(r)) response=true;
      });
    }
    if(!response) this._feedback.setShowMessage(false, 'Insufficient permissions');
    return response;
  }

}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardGuard } from './guards/auth-guard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./auth/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./auth/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'system',
    loadChildren: () => import('./pages/system/system.module').then( m => m.SystemPageModule),
    canActivate: [AuthGuardGuard],
    data: {roles:['Super Admin']}
  },
  {
    path: 'my-account',
    loadChildren: () => import('./pages/account/account/account.module').then( m => m.AccountPageModule)
  },
  {
    path: 'crm',
    loadChildren: () => import('./pages/crm/crm.module').then( m => m.CrmPageModule),
    canActivate: [AuthGuardGuard],
    data: {not:['Data Manager']}
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./pages/suppliers/suppliers.module').then( m => m.SuppliersPageModule),
    canActivate: [AuthGuardGuard],
    data: {not:['Sales Agent', 'Data Manager']}
  },
  {
    path: 'products',
    loadChildren: () => import('./pages/products/products.module').then( m => m.ProductsPageModule),
    canActivate: [AuthGuardGuard],
    data: {not:['Sales Agent', 'Data Manager']}
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import { User } from './user';
export class Auth {
  token: string;
  user:  User;

  constructor(response?)
  {
    if(response)
    {
      this.token=response.token;
      this.user=new User(response.user);
    }
  }
}

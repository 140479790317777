import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SystemService {
  public currentRoute = new BehaviorSubject<string>(window.location.pathname);
  public darkModeOn = new BehaviorSubject<boolean>(true);
  public staticCurrentRoute:string=window.location.pathname;
  public systemDark = window.matchMedia("(prefers-color-scheme: dark)");
  public saperpTheme = localStorage.getItem('saperptheme');

  constructor(
    private _router:Router
  )
  {
    this.updateCurrentRoute();
    this.darkMode();
  }

  public updateCurrentRoute()
  {
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:any) => {
      this.staticCurrentRoute=event.url;
      this.currentRoute.next(event.url);
    });
  }

  private darkMode()
  {
    if(this.saperpTheme)
    {
      if(this.saperpTheme == 'dark' || !this.saperpTheme)
      {
        this.setDarkMode();
      }
      else
      {
        this.setLightMode();
      }
    }
    else
    {
      if(this.systemDark.matches)
      {
        this.setDarkMode();
      }
      else
      {
        this.setLightMode();
      }
    }
    this.systemDark.addEventListener("change", () => {
      if (this.systemDark.matches) {
        this.setDarkMode();
      } else {
        this.setLightMode();
      }
    });
  }

  public setDarkMode()
  {
    document.body.classList.add('theme-dark');
    localStorage.setItem('saperptheme', 'dark');
    this.darkModeOn.next(true);
  }

  public setLightMode()
  {
    document.body.classList.remove('theme-dark');
    localStorage.setItem('saperptheme', 'light');
    this.darkModeOn.next(false);
  }
}

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FeedbackService {
  public showWorking = new BehaviorSubject<boolean>(false);
  public showMessage = new BehaviorSubject<any>({});

  constructor() { }

  public setShowWorking()
  {
    this.showWorking.next(true);
  }

  public unsetShowWorking()
  {
    setTimeout(() => this.showWorking.next(false),500);
  }

  public setShowMessage(good:boolean=true, message:string, duration:number=4000)
  {
    this.showMessage.next({
      good:good,
      message:message,
      duration:duration
    });
  }
}

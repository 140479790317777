import { Component, NgModule, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { SystemService } from './services/system.service';
import { LoadingController, MenuController, ModalController, ToastController } from '@ionic/angular';
import { FeedbackService } from './services/feedback.service';
import { environment } from '../environments/environment';
import { filter } from 'rxjs/operators';
import { PinlockPage } from './pages/account/pinlock/pinlock.page';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public appPages:any = [
    { title: 'Dashboard', url: '/dashboard', icon: 'logo-angular' },
    { title: 'CRM', url: '/crm', icon: 'prism-outline', not: ['Data Manager'], children: [
      { title: 'Leads', url: '/crm/leads', icon: 'fish-outline' },
      { title: 'Customers', url: '/crm/customers', icon: 'people-circle-outline'},
      { title: 'Appointments', url: '/crm/appointments', icon: 'calendar' }
    ]},
    { title: 'Products', url: '/products', icon: 'cube', not:['Sales Agent', 'Data Manager']},
    { title: 'Sales', url: '/fail', icon: 'pricetags-outline', not:['Sales Agent', 'Data Manager'] },
    { title: 'Users', url: '/usrs', icon: 'people-outline', not:['Sales Agent', 'Data Manager'], children: [
      { title: 'List', url: '/users', icon: 'list-outline' },
      { title: 'Create', url: '/users/create', icon: 'add-outline' }
    ] },
    { title: 'Suppliers', url: '/suppliers', icon: 'business-outline', not:['Sales Agent', 'Data Manager']},
    { title: 'Accounts', url: '/accounts', icon: 'library-outline', not:['Sales Agent', 'Data Manager'], children: [
      { title: 'Stuff', url: '/accounts', icon: 'list-outline' },
      { title: 'Stuff', url: '/accounts', icon: 'list-outline' },
      { title: 'Stuff', url: '/accounts', icon: 'list-outline' },
      { title: 'Stuff', url: '/accounts', icon: 'list-outline' }
    ] },
  ];
  public visibleAppPages:any=[];
  public showSideNav:boolean=false;
  public currentRoute: string;
  private loaderVisible:boolean=false;
  public version:string='';
  private adminMenuIncluded:boolean=false;
  public systemDark = window.matchMedia("(prefers-color-scheme: dark)");
  public saperpTheme = localStorage.getItem('saperptheme');
  private pinModal:boolean=false;
  public username:string='';
  public date:Date = new Date();

  constructor(
    private _router:Router,
    private _auth: AuthService,
    private _sys: SystemService,
    private loadingController: LoadingController,
    private toastController: ToastController,
    private _feedback: FeedbackService,
    private _menuCtrl: MenuController,
    private _modalCtrl:ModalController
  )
  {
    this.version=environment.version;
    this.setSubscriptions();
    this._auth.startTimer();
    this.resetUser();
    this.filterAppPages();
  }

  ngOnInit()
  {

  }

  private resetMenu()
  {
    this.visibleAppPages=[];
    this.adminMenuIncluded=false;
  }

  private resetUser()
  {
    this.username=this._auth.user.first_name +  ' ' + this._auth.user.last_name;
  }

  private filterAppPages()
  {

    this.visibleAppPages=this.appPages.filter(p => {
      if(this._auth.testUserRole('Super Admin')) return true;
      let response = true;
      if(p.not)
      {
        p.not.forEach(r => {
          if(this._auth.testUserRole(r)) response=false;
        });
      }
      if(p.roles)
      {
        let response = false;
        p.roles.forEach(r => {
          if(this._auth.testUserRole(r)) response=true;
        });
      }
      return response;
    });

  }

  public closeMenu()
  {
    this._menuCtrl.close();
  }

  private setSubscriptions()
  {
    this._router.events.pipe(
      filter(
        (event): event is NavigationEnd => event instanceof NavigationEnd
      )
    ).forEach(next => {
      if(
        next.urlAfterRedirects != '/login'
        && next.urlAfterRedirects != '/forgot-password'
        && next.urlAfterRedirects != '/forgot-password/complete'
      )
      {
        this._auth.startTimer();
      }
      else
      {
        this._auth.canceltimer();
      }
    });

    this._auth.accountChanged.subscribe(res => {
      this.resetUser();
      this.resetMenu();
      this.filterAppPages();
      this.showAdminMenu();
    });

    this._sys.currentRoute.subscribe(res => {
      this.currentRoute=res;
      this.setSidenavDisplay(res);
      this.showAdminMenu();
      this.setInitialMenuState();
    });

    this._feedback.showWorking.subscribe(res => {
      if(res)
      {
        this.createLoader();
      }
      else
      {
        this.destroyLoader();
      }
    });

    this._feedback.showMessage.subscribe((res:any) => {
      if(res.message) this.createToast(res.good, res.message, res.duration);
    });

    this._auth.loggedIn.subscribe(
      res => {
        if(!res)
        {
          if(
            this.currentRoute != '/login'
            && this.currentRoute != '/forgot-password'
            && this.currentRoute != '/forgot/complete'
          )
          {
            this._auth.canceltimer();
            this._router.navigate(['login']);
          }
        }
      }
    );

    this._auth.ping();

    this._auth.needPin.subscribe(
      res => {
        if(res) this.showPinLock();
      }
    );
  }

  async showPinLock()
  {
    if(this.pinModal) return;
    this.pinModal=true;
    const modal = await this._modalCtrl.create({
      component: PinlockPage,
      cssClass: 'auto-height dark-overlay',
      backdropDismiss: false,
      keyboardClose: false,
      swipeToClose: false
    });
    modal.onDidDismiss()
      .then((data) => {
        this.pinModal=false;
    });
    return await modal.present();
  }

  private showAdminMenu()
  {
    if(this.adminMenuIncluded) return;
    if(this._auth.testUserRole('Super Admin'))
    {
      this.adminMenuIncluded=true;
      this.visibleAppPages.push({
        title: 'System',
        url: '/system',
        icon: 'settings-outline',
        children: [
          {
            title: 'Organisations',
            url: '/system/organisations',
            icon: 'business-outline'
          },
          {
            title: 'Departments',
            url: '/system/departments',
            icon: 'apps-outline'
          },
          {
            title: 'Users',
            url: '/system/users',
            icon: 'people-outline'
          }
        ]
      });
    }
  }

  private setInitialMenuState()
  {
    this.appPages.forEach(p => {
      p.open=false;
      if(
        (
          p.url == this.currentRoute
          || this.currentRoute.startsWith(p.url)
        )
        && p.children) this.setOpenMenu(p.url);
      if(p.children)
      {
        p.children.forEach(c => {
          if(c.url == this.currentRoute) this.setOpenMenu(p.url);
        });
      }
    });
  }

  public setOpenMenu(url:string)
  {
    this.appPages.forEach(p => {
      p.open=false;
      if(p.url == url) p.open=true;
    });
  }

  public destroyLoader()
  {
    if(this.loaderVisible) this.loadingController.dismiss();
    this.loaderVisible=false;
  }

  async createLoader()
  {
    const loading = await this.loadingController.create({
      spinner: 'dots',
      message: 'Working on it...',
      translucent: false,
      cssClass: 'custom-class custom-loading',
      backdropDismiss: false,
      keyboardClose: false,
      duration: 3000
    });
    await loading.present();
    this.loaderVisible=true;
  }

  async createToast(good:boolean=true, message:string, duration:number=4000)
  {
    let color:string='success';
    if(!good) color='danger';
    const toast = await this.toastController.create({
      message: message,
      duration: duration,
      position: 'bottom',
      color: color
    });
    toast.present();
  }

  public setSidenavDisplay(res)
  {
    if(
      res != '/login'
      && res != '/forgot-password'
      && res != '/forgot/complete'
    )
    {
      this.showSideNav=true;
    }
    else
    {
      this.showSideNav=false;
    }
  }

  public logout()
  {
    this.showSideNav=false;
    this.resetMenu();
    this._auth.logout();
  }
}

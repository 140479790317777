import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';
import { User } from '../models/user';
import { Auth } from '../models/auth';
import { SystemService } from './system.service';
import { FeedbackService } from './feedback.service';
import { Role } from '../models/role';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public loggedIn = new BehaviorSubject<boolean>(false);
  public needPin = new BehaviorSubject<boolean>(false);
  public accountChanged = new BehaviorSubject<number>(0);
  public acc = new Auth();
  public user = new User();
  private timeoutValue:number=3600000;
  private pinTimeout;

  constructor(
    private _http: HttpClient,
    private _router: Router,
    private _sys: SystemService,
    private _feedback:FeedbackService
  )
  {
    if(environment.pinlockTimeout) this.timeoutValue=environment.pinlockTimeout;
    let acc=this.getUser();
    if(acc)
    {
      this.user = new User(acc.user);
      this.acc = new Auth(acc);
      this.loggedIn.next(true);
      this.startTimer();
    }
    else
    {
      this.canceltimer();
      let staticRoute=this._sys.staticCurrentRoute;
      if(
        staticRoute != '/login'
        && staticRoute != '/forgot-password'
        && staticRoute != '/forgot/complete'
      ) this._router.navigate(['login']);
    }
  }

  public validatePin(pin:number):Observable<boolean>
  {
    return this._http.post(environment.apiUrl+`pincheck`, {
      pin: pin
    }).pipe(
      map(
        (res:any) => {
          if(res instanceof HttpErrorResponse)
          {
            switch (res.status) {
              case 401:
              case 403:
                this.logout();
              break;
            }
          }
          if(res.error)
          {
            this._feedback.setShowMessage(false, 'Error unlocking with PIN');
            this.logout();
            return true;
          }
          if(res.result) return res.result;
          return false;
        }
      )
    );
  }

  public updatePin(newPin:number, newPinConfirm:number):Observable<boolean>
  {
    return this._http.put(environment.apiUrl+`changePin`, {
      newPin:newPin,
      newPinConfirm:newPinConfirm
    }).pipe(
      map(
        (res:any) => {
          if(res.result) return res.result;
          return false;
        }
      )
    );
  }

  public resetTimer()
  {
    this.canceltimer();
    this.startTimer();
  }

  public startTimer()
  {
    if(localStorage.getItem('happymonday') == 'yes')
    {
      this.needPin.next(true);
    }
    else
    {
      clearTimeout(this.pinTimeout);
      this.pinTimeout=setTimeout(() => {
        this.needPin.next(true);
        localStorage.setItem('happymonday', 'yes');
      }, this.timeoutValue);
    }
  }

  public canceltimer()
  {
    localStorage.setItem('happymonday', 'no');
    clearTimeout(this.pinTimeout);
    this.needPin.next(false);
  }

  public refreshToken()
  {
    this.getToken().subscribe(
      res => {
        if(res instanceof HttpErrorResponse)
        {
          switch (res.status) {
            case 401:
            case 403:
              this.logout();
            break;
          }
        }
        if(res.access_token)
        {
          let acc = this.getUser();
          if(acc)
          {
            acc.token = res.access_token;
            this.storeAccount(acc);
          }
        }
      }
    );
  }

  public getToken():Observable<any>
  {
    return this._http.get(environment.apiUrl+`refresh`).pipe(
      map(
        (res:any) => {
          return res;
        }
      ),
      catchError((error)=>{
        return [error];
      })
    );
  }

  public testUserRole(role:string)
  {
    if(!this.user || !this.user.roles) return false;
    let test:boolean=false;
    this.user.roles.forEach(r => {
      if(r.name == role || r.name == 'Super Admin')
      {
        test=true;
      }
    });
    return test;
  }

  public login(user:string, pass:string, otp:string, otpSent:boolean):Observable<any>
  {
    return this._http.post(environment.apiUrl+`login`,{
      email: user,
      password: pass,
      authenticator_code_sent: otpSent,
      otp: otp
    }).pipe(
      map(
        (res:any) => {
          if(res.user && res.token)
          {
            this.storeAccount(res);
            this.loggedIn.next(true);
            this.startTimer();
          }
          return res;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public forgotPasswordRequest(email:string):Observable<any>
  {
    return this._http.post(environment.apiUrl+`forgot`, {
      email: email
    }).pipe(
      map(
        res=>{
          return res;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public forgotPasswordComplete(email:string, password:string, password_confirmation:string, token:string):Observable<any>
  {
    return this._http.post(environment.apiUrl+`forgot/complete`, {
      email: email,
      password: password,
      password_confirmation: password_confirmation,
      token: token
    }).pipe(
      map(
        res=>{
          return res;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public changePassword(email: string, password:string, new_password:string, new_password_confirmation:string):Observable<any>
  {
    return this._http.put(environment.apiUrl+`changepassword`, {
      email:email,
      password:password,
      new_password:new_password,
      new_password_confirmation:new_password_confirmation
    }).pipe(
      map(
        (res:any)=>{
          return res;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public getUser()
  {
    let acc = localStorage.getItem('acc');
    try {
      if(acc) acc = atob(acc);
      let data = JSON.parse(acc);
      if(!data || !data.token) return false;
      return data;
    } catch (error) {
      return false;
    }
  }

  public updateAccount(user:User) {
    let acc = this.getUser();
    acc.user = user;
    this.storeAccount(acc);
  }

  private storeAccount(acc)
  {
    this.acc = new Auth(acc);
    this.user = new User(acc.user);
    let data = JSON.stringify(this.acc);
    localStorage.setItem('acc', btoa(data));
    this.accountChanged.next(acc.user.id);
  }

  public logout()
  {
    this.canceltimer();
    this._router.navigate(['login']);
    this.apiLogout().subscribe();
    this.destroyUser();
    return;
  }

  public destroyUser()
  {
    this._http.get(environment.apiUrl+`logout`);
    localStorage.removeItem('acc');
    this.acc=new Auth();
    this.user=new User();
    this.loggedIn.next(false);
  }

  private apiLogout():Observable<any>
  {
    return this._http.get(environment.apiUrl+`logout`).pipe(
      map(
        (res:any) => {
          return res;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  public ping()
  {
    this.pingAPI().subscribe(
      res => {
        if(res instanceof HttpErrorResponse)
        {
          switch (res.status) {
            case 401:
            case 403:
            case 500:
              this.canceltimer();
              this.logout();
            break;
          }
        }
      }
    );
  }

  public pingAPI():Observable<any>
  {
    return this._http.get(environment.apiUrl+`ping`).pipe(
      map(
        (res:any) => {
          return res;
        }
      ),
      catchError((error)=>{
        return [error];
      })
    );
  }

  public updateProfile(user:User):Observable<any>
  {
    return this._http.put(environment.apiUrl+`updateProfile/${user.id}`, {
      user:user,
    }).pipe(
      map(
        (res:any) => {
          if(
            res
            && res.success
          )
          {
            return res;
          }
          if(
            res &&
            res.error
          )
          {
            this._feedback.setShowMessage(false, res.error);
          }
          return false;
        }
      ),
      catchError((error)=>{
        return [error.error.error];
      })
    );
  }

  // public updateProfile(user:User):Observable<boolean>{
  //   return this._http.put(environment.apiUrl+`updateProfile/${product.id}`, {
  //     product:product
  //   }).pipe(
  //     map(
  //       (res:any) => {
  //         if(
  //           res
  //           && res.success
  //         )
  //         {
  //           return true;
  //         }
  //         if(
  //           res &&
  //           res.error
  //         )
  //         {
  //           this._feedback.setShowMessage(false, res.error);
  //         }
  //         return false;
  //       }
  //     ),
  //     catchError((error)=>{
  //       this._feedback.setShowMessage(false, error.error.error);
  //       return [error.error.error];
  //     })
  //   );
  // }
}

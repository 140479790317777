export class Role {
  id:number;
  name:string;

  constructor(role?)
  {
    if(role)
    {
      this.id = role.id;
      this.name = role.name;
    }
  }
}

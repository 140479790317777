import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { PinlockPageRoutingModule } from './pinlock-routing.module';

import { PinlockPage } from './pinlock.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PinlockPageRoutingModule
  ],
  declarations: [PinlockPage]
})
export class PinlockPageModule {}

import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import { FeedbackService } from '../services/feedback.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public _auth: AuthService,
    private _router: Router,
    private _feedback:FeedbackService
  )
  {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ):Observable<HttpEvent<any>>
  {
    request=request.clone(
      {
        setHeaders: {
          Authorization: `Bearer ${this._auth.acc.token}`
        }
      }
    );
    return next.handle(request).pipe(
      tap(
        event => {
          this._auth.startTimer();
        }, err => {
          switch (err.status) {
            case 403:
            case 401:
              this._feedback.setShowMessage(false, err.error.message);
              if(err.error.message == 'User is not logged in.')
              {
                this._auth.logout();
                return;
              }
            break;
            case 422:
              this._feedback.setShowMessage(false, err.error.message);
            break;
          }
        }
      )
    );
  }
}
